@font-face {
  font-family: 'morvarid';
  src: local('morvarid'), url(./fonts/morvarid.ttf) format('truetype');
}

body {
    background-color: #f7f8fc;
}


* {
    /* font-family: 'Noto Sans JP' !important; */
    font-family:  'morvarid', 'Heebo', sans-serif !important;
}